import React from "react";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import ArrowRight from "../../../../../assets/img/arrow-right.inline.svg"
import { Link } from "gatsby";

const Card = (props) => {
    const location = useLocation();

    const { icon, title, subText, linkTo } = props.items;

    const openURLInNewWindow = (url) => {
        window.open(url, "_blank", "nofollow noopener,noreferrer")
    }
    return (
        <div className="card card-item">
            <div className="card-icon--spacing">
                {icon}
            </div>

            <div className="card-text--wrap">
                <p className={`text-xlbold card-text--title ${location?.pathname.includes("/en-ng/business") ? 'color-black' : 'color-primary'}`} onClick={() => openURLInNewWindow(linkTo)}>{title}</p>
                <p className="card-text--subText">{subText}</p>
            </div>

            <Link to={linkTo} target="_blank" rel="nofollow noopener noreferrer" className={`learn-more ${location?.pathname.includes("/en-ng/business") ? 'color-black' : 'color-primary'} flex align-center cta_button`} >
                <span className="learn-more--icon">Learn more</span><span className="arrow-right--icon"><ArrowRight /></span>
            </Link>
        </div>
    )
}

const MoreForYouComponent = (props) => {
    const location = useLocation();
    
    return (
        <MoreForYouContainer className="kuda-section">
            <div className="kuda-section--inner">
                <div className="text-center">
                    <h2 className={`kuda-section--heading text-center text-xlbold  ${location?.pathname.includes("/en-ng/business") ? 'color-black' : 'color-primary'} title-bottom--spacing`}>
                        More for you
                    </h2>
                </div>


                <div className="kuda-below--inner">
                    <div className="kuda-summary--cards flex summary-top justify-between kuda-section--inner flex-wrap">
                        {props.moreForYou?.map((item, i) =>
                            <Card items={item}  key={i}/>
                        )}
                    </div>

                </div>
            </div>
        </MoreForYouContainer>

    )
}


const MoreForYouContainer = styled.div`

.kuda-section--heading{
    font-weight: 900;
    font-size: 36px;
    line-height: 50px;
}

.title-bottom--spacing {
    margin-bottom: 40px;
}

.kuda-below--inner .card{
    height: 291px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
}

.card-text--wrap .card-text--title{
    font-family: Mulish;
    font-size: 24px;
    line-height: 30.12px;
    margin-bottom: 15px;
    cursor: pointer;
}

.card-text--wrap .card-text--subText{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--blackColor);
}

@media only screen and (max-width: 769px){
    .kuda-below--inner .card{
        height: 245px;
    }
    .kuda-section--heading{
        font-size: 28px;
        line-height: 37px;
    }
    .card-text--wrap {

        .card-text--subText{
            line-height: 20px;
            font-size: 14px;
            margin-left: 0px;
        } 
        
        .card-text--title{
            font-size: 20px;
            margin-left: 0px;
            line-height: 25.1px;
        }
    }
}

`


export default MoreForYouComponent;